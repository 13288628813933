// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/ui/currencyIcon/Cr.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/ui/currencyIcon/GLD.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/ui/currencyIcon/RUB.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".root_div[data-v-ae6e6e42]{overflow:visible;text-align:center;vertical-align:middle}.root_div span[data-v-ae6e6e42]{align-items:center;display:inline-flex;line-height:1em}.root_div .currency_icon[data-v-ae6e6e42]{background-color:transparent;background-repeat:no-repeat;background-size:auto 400%;display:inline-block;flex-shrink:0;height:100%;height:1em;min-width:1em;text-align:center;vertical-align:top;width:auto}.root_div .currency_icon[data-v-ae6e6e42],.root_div .currency_icon.currency_style_0[data-v-ae6e6e42]{background-position:center 0}.root_div .currency_icon.currency_style_1[data-v-ae6e6e42]{background-position:center 33.3333%}.root_div .currency_icon.currency_style_2[data-v-ae6e6e42]{background-position:center 66.6666%}.root_div .currency_icon.currency_style_3[data-v-ae6e6e42]{background-position:center 100%}.root_div .currency_icon.currency_Cr[data-v-ae6e6e42]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.root_div .currency_icon.currency_GLD[data-v-ae6e6e42]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.root_div .currency_icon.currency_RUB[data-v-ae6e6e42]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
